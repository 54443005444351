import React, { forwardRef, memo, useCallback } from 'react';
import Input from './Input';

const WrappedInput = memo(
   forwardRef(({ Component, name, onChange, ...props }, ref) => {
      const handleChange = useCallback(
         (v) => {
            if (onChange) {
               if (!v?.target) {
                  onChange(name, v);
               } else {
                  onChange(name, v.target.value);
               }
            }
         },
         [onChange, name]
      );

      let RenderedComponent = Input;
      if (Component) {
         RenderedComponent = Component;
      }
      return <RenderedComponent {...props} name={name} ref={ref} onChange={handleChange} />;
   })
);

export default WrappedInput;
