import FileDropzone from '@components/FileDropzone';
import InputUploader from '@components/InputUploader';
import IconAttachFile from '@icons/IconAttachFile';
import IconRemoveCircle2 from '@icons/IconRemoveCircle2';
import React, { memo, useCallback } from 'react';

const Attachments = memo(({ name, value, onChange }) => {
   const onUpload = useCallback(
      (files) => {
         let newFiles = Array.from(files).map((file) => file);
         onChange && onChange(name, [...(value || []), ...newFiles]);
      },
      [value, name, onChange]
   );

   const removeFileAtIndex = (fileIndex) => {
      let newFiles = [...value];
      newFiles.splice(fileIndex, 1);
      onChange && onChange(name, newFiles);
   };

   const fileList = value || [];

   return (
      <FileDropzone
         className="attachments-input__dropzone"
         placeholderDropMessage="Drop files for instant upload"
         onChange={onUpload}
      >
         <div className="attachments-input">
            <InputUploader
               className="attachments-input__uploader"
               multiple
               onChange={onUpload}
            >
               <span>Attachments</span>
               <i>
                  <IconAttachFile />
               </i>
            </InputUploader>
            {fileList.length > 0 &&
               fileList.map((file, index) => {
                  return (
                     <div
                        className="attachments-input__file"
                        key={`${file.name}_${index}`}
                     >
                        <span>{file.name}</span>
                        <button onClick={() => removeFileAtIndex(index)}>
                           <IconRemoveCircle2 />
                        </button>
                     </div>
                  );
               })}
         </div>
      </FileDropzone>
   );
});

export default Attachments;
