import React from 'react';

const IconAttachFile = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M8.4016 8.09913L6.6119 9.88884C5.61923 10.8815 5.61923 12.4828 6.6119 13.4755C7.60457 14.4682 9.20589 14.4682 10.1986 13.4755L13.0172 10.6569C14.9953 8.6788 14.9953 5.46167 13.0172 3.48357C11.0391 1.50548 7.82194 1.50548 5.84384 3.48357L2.77163 6.55578C1.07612 8.2513 1.07612 11.0047 2.77163 12.7075"
         stroke="currentColor"
         strokeLinecap="square"
         strokeLinejoin="round"
      />
   </svg>
);

export default IconAttachFile;
