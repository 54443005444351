import Input from '@components/Input';
import { message } from '@components/Message';
import SEO from '@components/SEO';
import WrappedInput from '@components/WrappedInput';
import IconArrow from '@icons/IconArrow';
import { feedbackService } from '@services/feedbackService';
import { isValidURL } from '@utils/common';
import constant from '@utils/constant';
import { FeedbackRole } from '@utils/enums/FeedbackStatus';
import { InputType } from '@utils/enums/InputType';
import { getProfileSelector } from '@utils/selectors';
import Footer from '@views/default-layout/footer/Footer';
import Attachments from '@views/feedback/Attachments';
import { Col, Form, Row } from 'antd';
import { navigate } from 'gatsby';
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const formDefinitions = [
   {
      label: 'Role',
      name: 'role',
      type: InputType.SELECT,
      options: [
         {
            label: 'Buyer',
            value: FeedbackRole.BUYER,
         },
         {
            label: 'Seller',
            value: FeedbackRole.SELLER,
         },
      ],
      required: true,
   },
   {
      label: 'Category',
      name: 'category',
      type: InputType.SELECT,
      notFoundMessage: 'No category',
      required: true,
   },
   {
      label: 'Your name',
      name: 'full_name',
      type: InputType.TEXT,
      required: true,
   },
   {
      label: 'Your email',
      name: 'email',
      type: InputType.TEXT,
      required: true,
   },
   {
      label: 'Phone number',
      name: 'phone_number',
      type: InputType.TEXT,
   },
   {
      label: 'Order ID',
      name: 'order_id',
      type: InputType.TEXT,
   },
   {
      label: 'Product link',
      name: 'product_url',
      type: InputType.TEXT,
   },
   {
      label: 'Content',
      name: 'content',
      type: InputType.TEXTAREA,
      required: true,
   },
   {
      label: 'Attachments',
      name: 'attachments',
      Component: Attachments,
   },
];

const Feedback = memo(() => {
   const { data: profileData, loading: profileLoading } = useSelector(getProfileSelector);
   const [values, setValues] = useState({
      role: 'BUYER',
      category: null,
      full_name: '',
      email: '',
      phone_number: '',
      order_id: '',
      product_url: '',
      content: '',
      attachment: [],
   });
   const [categoryOptions, setCategoryOptions] = useState([]);
   const [categories, setCategories] = useState([]);
   const [errors, setErrors] = useState({});
   const [submitting, setSubmitting] = useState(false);
   const [inputProps, setInputProps] = useState({});

   useEffect(() => {
      const getAllCategories = async () => {
         const response = await feedbackService.getFeedbackCategoryMatrix({
            page: 1,
            page_size: 10000,
         });
         if (response.isSuccess && response.data?.content?.length) {
            setCategoryOptions(
               response.data.content.map(({ uid, name }) => ({ label: name, value: uid }))
            );
            setCategories(response.data.content);
         }
      };
      getAllCategories();
   }, []);

   useEffect(() => {
      if (!profileLoading && profileData?.username) {
         console.log(profileData);
         setValues((prevValue) => ({
            ...prevValue,
            full_name: profileData.full_name || '',
            email: profileData.email || '',
            phone_number: profileData.phone_number || '',
         }));
      }
   }, [profileLoading, profileData]);

   useEffect(() => {
      const inputProps = {};
      formDefinitions.forEach((item) => {
         // required
         const getRequired = (name, required) => {
            if (required) return required;
            if (name === 'phone_number' && values.category?.require_phone_number)
               return true;
            if (name === 'order_id' && values.category?.require_order_id) return true;
            if (name === 'product_url' && values.category?.require_product_url)
               return true;
            return false;
         };
         const isRequired = getRequired(item.name, item.required);
         // options
         const getOptionss = (name) => {
            if (name === 'category') {
               return categoryOptions;
            }
            return void 0;
         };
         const optionList = item.options || getOptionss(item.name);

         inputProps[item.name] = {
            ...item,
            required: isRequired,
            options: optionList,
         };
      });
      setInputProps(inputProps);
   }, [values, categoryOptions]);

   const validate = useCallback(
      (name, value) => {
         let error = '';
         if (inputProps[name].required && !value) {
            const itemDef = formDefinitions.find((i) => i.name === name);
            error = `Please input ${itemDef.label}`;
            if (name === 'category' || name === 'role') {
               error = `Please select ${itemDef.label}`;
            }
         } else {
            if (name === 'product_url' && `${value}`.trim() && !isValidURL(value)) {
               error = 'Please input a valid product url';
            }
         }
         return error;
      },
      [inputProps]
   );

   const handleChange = useCallback(
      (name, value) => {
         let newValue = value;
         if (name === 'category' && value) {
            const selectedCategory = categories.find((c) => c.uid === value);
            newValue = selectedCategory || '';
         }
         setValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
         }));
         setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validate(name, value),
         }));
      },
      [categories, validate]
   );

   const submit = async () => {
      let errors = {};
      formDefinitions.forEach((item) => {
         const error = validate(item.name, values[item.name]);
         if (error) {
            errors = {
               ...errors,
               [item.name]: error,
            };
         }
      });
      console.log(errors);
      if (Object.keys(errors).length) {
         setErrors(errors);
         return;
      }
      await setSubmitting(true);
      const response = await feedbackService.createFeedback({
         ...values,
         category_uid: values.category?.uid || '',
      });
      if (response.isSuccess) {
         message.success('Your feedback has been sent. Thank you for your feedback!');
         setTimeout(() => {
            navigate(constant.ROUTE_HOME);
         }, 500);
      } else {
         message.error(response.message);
      }
      setSubmitting(false);
   };

   const renderInput = useCallback(
      (props) => {
         const { Component, label, ...ownprops } = props;
         const RenderedComponent = Component || WrappedInput;

         const getValue = (name) => {
            if (name === 'category') {
               return values[name]?.uid || '';
            }
            return values[name] || '';
         };
         const value = getValue(ownprops.name);
         const itemProps = inputProps[ownprops.name] || {};
         return (
            <div>
               <RenderedComponent
                  {...ownprops}
                  {...itemProps}
                  placeholder={`${label}${itemProps.required ? ' *' : ''}`}
                  inputTitle={
                     !itemProps.required ? (
                        label
                     ) : (
                        <>
                           {label} <span className="required">*</span>
                        </>
                     )
                  }
                  value={value}
                  onChange={handleChange}
               />
               {errors[ownprops.name] && (
                  <span className="feedback-page__error">{errors[ownprops.name]}</span>
               )}
            </div>
         );
      },
      [handleChange, inputProps, values, errors]
   );

   return (
      <>
         <SEO title={'Feedback'} />
         <div className="login-register feedback-page">
            <div className="section-title">
               <span className="section-title-name animate__animated animate__fadeIn">
                  FEEDBACK
                  <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                     <IconArrow />
                  </span>
               </span>
            </div>
            <Row gutter={30}>
               <Col xl={10} lg={14} md={24} sm={24} xs={24}>
                  <div className="feedback-page__message">
                     Your idea can help us improve...
                  </div>
                  <div className="submit-form">
                     {formDefinitions.map((item) => {
                        return <Fragment key={item.name}>{renderInput(item)}</Fragment>;
                     })}
                     <button
                        className="common-button"
                        type="primary"
                        disabled={submitting}
                        onClick={submit}
                     >
                        {submitting ? 'Sending...' : 'Send Feedback'}
                     </button>
                  </div>
               </Col>
            </Row>
         </div>
         <Footer />
      </>
   );
});

export default Feedback;
