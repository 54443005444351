import React from 'react';

const IconRemoveCircle2 = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <circle cx={8} cy={8} r={8} fill="black" fillOpacity="0.5" />
      <path
         d="M11 5L5 11"
         stroke="white"
         strokeMiterlimit={10}
         strokeLinejoin="round"
      />
      <path
         d="M5 5L11 11"
         stroke="white"
         strokeMiterlimit={10}
         strokeLinejoin="round"
      />
   </svg>
);

export default IconRemoveCircle2;